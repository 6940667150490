import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { createRobotRoom, createClientRoom } from '@/services/room';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'base',
		redirect: { name: 'room' },
	},
	{
		path: '/room',
		name: 'room',
		component: () => import('@/views/Room.vue'),
		beforeEnter: async (to, from, next) => {
			try {
				const room = await createClientRoom({ ...to.params, ...to.query });
				if (room.success === false) {
					return next({ name: 'error' });
				}
				const { id, ...query } = room;
				next({ name: 'activated-room', params: { id }, query });
			} catch (e) {
				return next({ name: 'error' });
			}
		},
	},
	{
		path: '/room/:id',
		name: 'activated-room',
		component: () => import('@/views/Room.vue'),
		beforeEnter: async (to, from, next) => {
			try {
				const room = await createClientRoom({ ...to.params, ...to.query });
				if (room.success === false) {
					return next({ name: 'error' });
				}
				const { id, ...query } = room;
				if (JSON.stringify(query) === JSON.stringify(to.query)) {
					return next();
				}
				return next({ name: 'activated-room', params: { id }, query });
			} catch (e) {
				return next({ name: 'error' });
			}
		},
	},
	{
		path: '/robot/room/:id',
		name: 'robot-streaming-room',
		component: () => import('@/views/Robot.vue'),
		beforeEnter: async (to, from, next) => {
			try {
				const room = await createRobotRoom({ ...to.params, ...to.query });

				if (room.success === false) {
					return next({ name: 'error' });
				}
				const { id, ...query } = room;
				if (JSON.stringify(query) === JSON.stringify(to.query)) {
					return next();
				}
				return next({ name: 'robot-streaming-room', params: { id }, query });
			} catch (e) {
				return next({ name: 'error' });
			}
		},
	},
	{
		path: '/wc-camera',
		name: 'camera',
		component: () => import('@/views/WCCamera.vue'),
	},
	{
		path: '/error',
		name: 'error',
		component: () => import('@/views/Error.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
