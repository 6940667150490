import { createApp } from 'vue';
import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import App from './App.vue';
import router from './router';
import 'vue-toastification/dist/index.css';

const toastOptions: PluginOptions = {
	position: POSITION.BOTTOM_LEFT,
};

createApp(App)
	.use(router)
	.use(Toast, toastOptions)
	.mount('#app');
